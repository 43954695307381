<template>
  <v-app>
    <div class="" id="">
      <!-- Navbar STart -->
      <Head></Head>
      <!-- Navbar End -->

      <!-- Hero Start -->
      <Banner></Banner>
      <!-- Hero End -->
      <section class="section container">
        <div class="">
          <div class="section-title mb-4 pb-2">
            <div class="wyns">
              <img src="../../public/images/services/h2.jpg" alt="" />
            </div>
            <!-- <h2 class="titre mb-4 text-center">Wynns tools</h2> -->
            <p class="equipment">
              <span class="tools">
                {{ $t("equipement.tools") }}
              </span>
              {{ $t("equipement.wyns1") }}
              {{ $t("equipement.wyns2") }}
            </p>
            <p class="equipment">
              {{ $t("equipement.wyns3") }}
              {{ $t("equipement.wyns4") }}
            </p>
            <p class="equipment">
              <span class="tools">
                {{ $t("equipement.tools") }}
                {{ $t("equipement.slug") }}
              </span>
            </p>
          </div>
        </div>
        <v-carousel
          cycle
          hide-delimiters
          show-arrows-on-hover
          :height="carouselHeight"
          :padding="padding"
        >
          <v-carousel-item
            v-for="(item, index) in items"
            :key="index"
            :height="itemHeight"
            @click="showPreview(index)"
          >
            <v-img :src="item.src" contain />
          </v-carousel-item>
        </v-carousel>
        <v-dialog v-model="showPreviewDialog" max-width="1200">
          <v-img :src="items[currentImageIndex].src" contain />
        </v-dialog>
        <div class="text-center mt-4">
          <a
            href="https://oanke.com/fr/shopHome/WYNN’S%20TOOLS%20"
            target="_blank"
          >
            <v-btn outlined large color="#049372">{{
              $t("equipement.shop")
            }}</v-btn>
          </a>
        </div>
      </section>
      <section class="section container">
        <div class="">
          <div class="section-title mb-4 pb-2">
            <div class="wyns">
              <img src="../../public/images/services/h22.jpg" alt="" />
            </div>
            <p class="equipment">
              {{ $t("equipement.car1") }}
            </p>
            <p class="equipment">
              {{ $t("equipement.car2") }}
            </p>
            <p class="equipment">
              {{ $t("equipement.car3") }}
            </p>
          </div>
        </div>

        <v-carousel
          cycle
          hide-delimiters
          show-arrows-on-hover
          :height="carouselHeight"
          :padding="padding"
        >
          <v-carousel-item
            v-for="(item, index) in items2"
            :key="index"
            :height="itemHeight"
            @click="showPreview2(index)"
          >
            <v-img :src="item.src" contain />
          </v-carousel-item>
        </v-carousel>
        <v-dialog v-model="showPreviewDialog2" max-width="1200">
          <v-img :src="items2[currentImageIndex2].src" contain />
        </v-dialog>
        <div class="text-center mt-4">
          <a
            href="https://oanke.com/fr/shopHome/S2A%20EQUIPEMENTS"
            target="_blank"
          >
            <v-btn outlined large color="#049372">{{
              $t("equipement.shop")
            }}</v-btn>
          </a>
        </div>
      </section>
      <!-- End services -->

      <!-- Footer Start -->
      <Foot></Foot>
      <!-- Footer End -->
    </div>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "../i18n";
import Foot from "../components/Foot.vue";
import Head from "../components/Head.vue";
import Banner from "../components/Banner.vue";
export default {
  name: "Services",
  components: {
    Foot,
    Head,
    Banner,
  },
  data: () => {
    return {
      image: {},
      model: null,
      model1: null,
      padding: "30px",
      items: [
        { src: "/images/services/img13.jpg" },
        { src: "/images/services/img14.jpg" },
        { src: "/images/services/img15.jpg" },
        { src: "/images/services/img16.jpg" },
        { src: "/images/services/img23.jpg" },
      ],
      items2: [
        { src: "/images/services/img18.jpg" },
        { src: "/images/services/img19.jpg" },
        { src: "/images/services/img20.jpg" },
        { src: "/images/services/img21.jpg" },
        { src: "/images/services/img22.jpg" },
      ],
      carouselHeight: "500px",
      padding: 0,
      showPreviewDialog: false,
      showPreviewDialog2: false,
      currentImageIndex: 0,
      currentImageIndex2: 0,
    };
  },
  computed: {
    ...mapGetters(["lng"]),
    carouselWidth() {
      return (this.carouselWidth / 5) * 2;
    },
    carouselHeight() {
      return this.$vuetify.breakpoint.width / 4;
    },
    itemWidth() {
      return (this.carouselWidth / 5) * 0.8;
    },
    itemHeight() {
      return this.carouselHeight;
    },
    customers: function() {
      return [
        { name: `${this.$t("services.avis")}`, text: "- Denkey Aser" },
        { name: `${this.$t("services.avis1")}`, text: "- Tossou Daniel" },
        { name: `${this.$t("services.avis2")}`, text: "- Dossou Franjuste" },
        { name: `${this.$t("services.avis3")}`, text: "- Onewin Femi" },
        { name: `${this.$t("services.avis4")}`, text: "- Taboukouna Florent" },
        { name: `${this.$t("services.avis5")}`, text: "- Gbeze Martin" },
      ];
    },
  },
  methods: {
    setImage(image) {
      this.image = image;
    },
    showPreview(index) {
      this.currentImageIndex = index;
      this.showPreviewDialog = true;
    },
    showPreview2(index) {
      this.currentImageIndex2 = index;
      this.showPreviewDialog2 = true;
    },
    visit: function() {
      window.open("https://oanke.com/fr/shopHome/9");
    },
    changedtofr: function() {
      i18n.locale = "fr";
      this.$store.commit("setlng", { lang: this.$t("lang1") });

      let language = this.$route.params.lang;
      if (language === "en") {
        let splitted = this.$route.path.split(`${language}`);
        let url = "/fr" + splitted[1];
        this.$router.push(url);
      }
    },
    changedtoen: function() {
      i18n.locale = "en";
      this.$store.commit("setlng", { lang: this.$t("lang2") });

      let language = this.$route.params.lang;
      if (language != "en") {
        let splitted = this.$route.path.split(`${language}`);
        let url = "/en" + splitted[1];
        this.$router.push(url);
      }
    },
    toggleMenu: function() {
      document.getElementById("isToggle").classList.toggle("open");
      var isOpen = document.getElementById("navigation");
      if (isOpen.style.display === "block") {
        isOpen.style.display = "none";
      } else {
        isOpen.style.display = "block";
      }
    },
  },
  mounted: function() {},
};
</script>

<style scoped>
.center {
  margin: auto;
  width: 60%;
  padding: 10px;
}
.img {
  max-width: 10rem;
}
.titre {
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
  font-family: "Roboto", sans-serif !important;
  color: #049372 !important;
  text-transform: uppercase;
}
.wyns {
  text-align: center;
}
.tools {
  color: #049372;
  font-weight: 500;
  font-size: 1.8rem;
}
.equipment {
  font-size: 1.1rem;
  color: #324054;
}
</style>
